<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="分站搭建" />
    <div class="column-item">
      <div class="title-item">二级域名</div>
      <div class="input-item">
        <div class="input"><input v-model="from.url1" type="text" placeholder="输入您想要的二级前缀可用字母数字" style="text-align: left;" /></div>
        <div class="btn" @click="handleRandom">随机</div>
      </div>
      <div class="input-item" @click="handleShowUrl">
        <div class="name" style="color: #276eff;">域名选择</div>
        <div class="input"><input v-model="from.url2" readonly type="text" /></div>
        <div class="right img-cover"><img src="../../assets/images/right-icon-back.png" /></div>
      </div>
    </div>
    <div class="tips-item">可用字母，数字建议为2-5字，不能有标点符号（尽量简短，便于推广宣传）</div>
    <div class="column-item">
      <div class="input-item">
        <div class="name">网站名称</div>
        <div class="input"><input v-model="from.SiteName" type="text" placeholder="请输入网站名称" /></div>
      </div>
    </div>
    <div class="column-item">
      <div class="input-item">
        <div class="name">QQ</div>
        <div class="input"><input v-model="from.Qq" type="text" placeholder="请输入QQ号码" /></div>
      </div>
    </div>
    <div class="column-item">
      <div class="input-item">
        <div class="name">微信号</div>
        <div class="input"><input v-model="from.Wx" type="text" placeholder="请输入微信号" /></div>
      </div>
    </div>
    <div class="column-item">
      <div class="input-item img-input-item">
        <div class="name">网站LOGO</div>
        <div class="img-item">
          <van-uploader name="Photo" :preview-image="false" :deletable="false" :after-read="handleUploadImg">
            <div v-if="from.Logo" class="img img-cover"><img :src="from.Logo" /></div>
            <div v-else class="add-img img-contain"><img src="../../assets/images/add-btn-icon.png" /></div>
          </van-uploader>
        </div>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="btn-item public-btn" @click="handleSubmit">
      <van-loading v-if="submiting" color="#ffffff" />
      <span v-else>确定</span>
    </div>
    <van-popup v-model="showUrl" :round="true" position="bottom">
      <div class="popup-item">
        <div class="page-header">
          域名选择
          <div class="colse-item img-cover" @click="showUrl = false"><img src="../../assets/images/close-icon.png" /></div>
        </div>
        <div class="url-list-item">
          <div v-for="(item, index) in urlList" :key="index" class="url-item" @click="selectedUrl = item">
            {{ item }}
            <div class="select img-cover" :class="selectedUrl == item ? 'active' : ''"><img src="../../assets/images/selected-icon.png" /></div>
          </div>
        </div>
        <div class="btn public-btn" @click="handleSelectedUrl">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "BuildSiteSubmit",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      Level: '',
      submiting: false,
      urlList: [],
      showUrl: false,
      selectedUrl: '',
      from: {
        url1: '',
        url2: '',
        SiteName: '',
        Qq: '',
        Wx: '',
        Logo: ''
      },
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    if (this.$route.query && this.$route.query.level) {
      this.Level = this.$route.query.level
    }
    this.handleGetUrlList()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleRandom() {
      const strArr = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('')
      const count = Math.round(Math.random() * 3) + 2
      let str = ''
      for (let index = 0; index < count; index++) {
        str += strArr[Math.round(Math.random() * (strArr.length - 1))]
      }
      this.from.url1 = str
    },
    handleShowUrl() {
      if (this.from.url2) {
        this.selectedUrl = this.from.url2
      }
      this.showUrl = true
    },
    handleSelectedUrl() {
      if (this.selectedUrl) {
        this.from.url2 = this.selectedUrl
        this.showUrl = false
      }
    },
    async handleGetUrlList() {
      var res = await this.$API.urlList.post()
      const list = []
      for (let i = 0; i < res.info.length; i++) {
        const item = res.info[i]
        list.push(item.MainUrl)
      }
      this.selectedUrl = list[0]
      this.urlList = list
    },
    async handleUploadImg(file) {
      var res = await this.$API.upload.post(file[0])
      if (res.error == 0) {
        this.from.Logo = res.info
      } else {
        this.$toast(res.info)
      }
    },
    async handleSubmit() {
      if (this.submiting) {
        return
      }
      const { from } = this
      if (!from.url1) {
        this.$toast('请输入二级域名')
        return
      }
      if (!from.url2) {
        this.$toast('请选择域名')
        return
      }
      from.Url = from.url1 + '.' + from.url2
      delete from.url1
      delete from.url2
      if (!from.SiteName) {
        this.$toast('请输入网站名称')
        return
      }
      if (!from.Qq) {
        this.$toast('请输入QQ号码')
        return
      }
      if (!from.Wx) {
        this.$toast('请输入微信号')
        return
      }
      if (!from.Logo) {
        this.$toast('请上传网站LOGO')
        return
      }
      const params= { ...from }
      if (params.Qq) {
        params.Qq = JSON.stringify({ num: params.Qq, status: 1 })
      }
      if (params.Wx) {
        params.Wx = JSON.stringify({ code: params.Wx, num: '', status: 1 })
      }
      from.Level = this.Level
      this.submiting = true
      var res = await this.$API.buySubstations.post(params)
      this.submiting = false
      if (res.error == 0) {
        this.GetUserInfo()
        this.$toast.success(res.info)
        this.$router.back()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.column-item {
  width: 6.86rem;
  margin-top: .24rem;
  background-color: #fff;
  border-radius: .16rem;
  overflow: hidden;
  .title-item {
    height: .4rem;
    line-height: .4rem;
    padding: .24rem 0 .16rem .24rem;
    background-color: rgba(19, 153, 255, .2);
    color: #276eff;
    font-size: .28rem;
    font-weight: bold;
  }
  .input-item {
    margin: 0 .24rem;
    border-bottom: .02rem solid #f8f8f8;
    height: .88rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #242941;
    font-size: .28rem;
    &.img-input-item {
      height: 2rem;
    }
    .name {
      margin-right: .24rem;
    }
    .input {
      flex: 1;
      input {
        text-align: right;
      }
    }
    .btn {
      cursor: pointer;
      color: #276eff;
      margin-left: .24rem;
    }
    .right {
      width: .16rem;
      height: .16rem;
      margin-left: .24rem;
    }
    .img-item {
      cursor: pointer;
      width: 1.6rem;
      height: 1.6rem;
      border: .02rem solid #f8f8f8;
      border-radius: .12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      /deep/.van-uploader__wrapper {
        margin-left: 0;
      }
      .img {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}
.tips-item {
  width: 6.86rem;
  margin-top: .24rem;
  line-height: .36rem;
  color: #ff2442;
  font-size: .24rem;
}
.btn-item {
  cursor: pointer;
  margin-bottom: .8rem;
  width: 6.86rem;
  height: .88rem;
  border-radius: .12rem;
  line-height: .88rem;
  text-align: center;
  color: #fff;
  font-size: .3rem;
  font-weight: bold;
}
/deep/.van-popup--bottom {
  width: var(--max-width) !important;
  left: 50%;
  margin-left: var(---max-width-50);
}
.popup-item {
  width: 100%;
  height: 7.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .page-header {
    width: 100%;
    position: relative;
    padding: .32rem 0;
    height: .48rem;
    line-height: .48rem;
    text-align: center;
    color: #030304;
    font-size: .32rem;
    font-weight: 500;
    .colse-item {
      width: .24rem;
      height: .24rem;
      padding: .12rem;
      position: absolute;
      right: .28rem;
      top: .32rem;
    }
  }
  .url-list-item {
    flex: 1;
    margin: .4rem 0;
    width: 6.86rem;
    .url-item {
      background-color: #f7f7f7;
      height: .88rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 .24rem;
      font-size: .24rem;
      color: #151d2b;
      &:nth-child(1) {
        border-top-left-radius: .16rem;
        border-top-right-radius: .16rem;
      }
      &:nth-last-child(1) {
        border-bottom-left-radius: .16rem;
        border-bottom-right-radius: .16rem;
      }
      .select {
        width: .3rem;
        height: .3rem;
        border: .04rem solid #fff;
        border-radius: 50%;
        img {
          display: none;
        }
        &.active {
          img {
            display: block;
          }
        }
      }
    }
  }
  .btn {
    margin-bottom: .8rem;
    width: 6.86rem;
    height: .88rem;
    border-radius: .12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: .28rem;
    font-weight: bold;
  }
}
</style>